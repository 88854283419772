import "core-js/modules/es.array.push.js";
import { StudentPage, UpdateAllStudent, getTeamList } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      gradeList: [],
      teamList: [],
      pageInfo: {
        pageSize: 10,
        name: '',
        student_grade: '',
        team_id: '',
        user_type: 6,
        current: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
    //this.getGradeList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.student_grade = '';
      this.pageInfo.team_id = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      StudentPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    checkGrade(code) {
      this.gradeList.find(res => {
        if (res.code == code) {
          this.teamList = res.team;
          this.pageInfo.team_id = '';
        }
      });
    },
    getGradeList() {
      getTeamList().then(res => {
        this.gradeList = res.data;
      });
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/school/schoolUser/studentEdit",
        query: {
          Info: data
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    updateStudentAll() {
      let that = this;
      let school = JSON.parse(localStorage.getItem('user'));
      if (school.id && this.user_type == 2) {} else {
        this.$root.ElMessage.error('身份信息异常');
        return false;
      }
      that.$root.ElMessageBox.confirm('同步数据，操作数据频繁，可能会短暂的影响用户使用，建议在访问量小的时候操作,你确定要现在操作吗?', '提示', {
        confirmButtonText: '继续操作',
        cancelButtonText: '我再想想',
        type: 'warning',
        draggable: true
      }).then(() => {
        this.sendUpdate(school.id);
      }).catch(() => {});
    },
    sendUpdate(school_id) {
      UpdateAllStudent({
        school_id: school_id
      }).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.search();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};